main
	padding 20px
	
.representados
	cf()	
main
	center(960px, 2%)

.mainFooter
	gradient black #082243
	padding 25px
	color white
	& svg
		max-width 100%	
	& p
		margin-bottom 0
		line-height 1.4
.mainFooter-Ass
	col(2/3)
	+tablet()
		col(3/6)
	+desktop()
		col(3/6)
	
.center
	center(960px)
	relative()
.cf
	cf()	

.card
	stack()
	margin-bottom rem(30px)
	&:nth-last-child
		margin-bottom 0
	& h1
		t-h3()
		margin 10px 0 5px 0
	+tablet()
		col(1/2, cycle: 2)
		& h1
			// margin-top rem(10px) !important
			
	+desktop()
		col(1/3, uncycle, cycle: 3)
		& h1
			t-h5()
			margin-top rem(11.3154px) !important
			
// Contato

.mapa
	stack()
	line-height 1.5em
	+tablet()
		col(1/2)
	+below(700px)
		stack()
	+desktop()
		col(1/2)
	& img
		margin-bottom 1em
.wpcf7-form
	stack()
	+tablet()
		col(1/2)
	+below(700px)
		stack()
	+desktop()
		col(1/2)
	& input, textarea
		input()
		width 100%
		margin-bottom 1em
		border-radius 0
		border-color $orange
		background-color #fafafa
	& input[type="submit"]
		 button()
		 click-down()
		 hover-darken()
		 border-radius 0
		 background-color $orange
		 float right
		 width 100px
// Quem Somos

.quemsomos
	stack()
	+above(750px)
		col(4/6)
	+desktop()
		col(4/6)
	& .mainHeading
		text-align left

.portfolio
	stack()
	& ul
		ul()
		& li
			line-height 1.3			
			+below(750px)
				col(1/2, cycle: 2)
	+above(750px)
		col(2/6)
	+desktop()
		col(2/6)
	& .mainHeading
		text-align left
	
		
	