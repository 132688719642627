.btn
	&.btn-contato
		center (200px)
	& a
		button()
		border-radius 0	
		text-align center
		color white
		background-color #0194b5
		cursor pointer
		display block
		hover-darken()