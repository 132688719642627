// Css for off-canvas mobile menu
.st-wrapper 	
	width 100%
	overflow hidden
	
.isShowingNav .push-wrap
		transform translate(-220px, 0)
		transform translate3d(-220px, 0, 0)		

.isShowingNav .mobileNav
	display block

.isHidingNav .mobileNav	
	display block
.push-wrap
	width 100%	
	transform translate(0, 0)
	transform translate3d(0, 0, 0)	
	transition .5s ease all
	background white

.mobileNav
	width 220px
	height 100%
	position fixed
	top 0
	right 0
	background #428bca
	padding 15px
	background: #2b343f;
	text-align: center
	box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.5)
	transition .4s ease all
	display none
	transition .4s ease all
	& ul 
		margin: 0;
		padding: 0;
		list-style: none;
		& li a
			display: block;
			text-decoration: none !important;
			height: 60px;
			line-height: 60px;
			font-size: 18px;
			color: #fff;			
			&:hover
				background: #4EFFFF;
				color: #000;
			&.active
				border-bottom 2px solid #4effff
	+above(700px)
		display none
	+desktop()
		display none
		


.mainHeader
	gradient black #082243

.mainHeader-Container
	cf()	
	padding 15px 10px
	
		
.mainHeader-logo
	span(4/5, cycle 2)
	& svg
		width 100%
	+above(700px)
		span(5/5, uncycle)
		padding 0 4%
	+desktop()
		span(5/5, uncycle)

.mainHeader-trigger
	span(1/5, cycle 2)
	vertical-align middle
	cursor pointer
	color white
	font-size 44px
	text-align center
	margin-top 5px
	-webkit-tap-highlight-color: rgba(0,0,0,0)
	-webkit-tap-highlight-color: transparent
	touch-action: manipulation
	+above(700px)
		display none
	+desktop()
		display none
		
.mainHeader-mainNavi	
	display none
	+above(700px)
		display block
	+desktop()
		display block	
	& ul
		
		& li
			col(1/5)
			text-align center
			& a
				display block
				color: #fff;
				height:  40px
				line-height 40px
				&.active
					border-bottom 2px solid #4effff				
				&:hover
					background: #4EFFFF;
					color: #000;
.mainNavi-Container
	center(800px)
	cf()
	padding rem(11.3154px) 0

a 
	transition all 300ms ease
