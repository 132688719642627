@import '../../../bower_components/typographic/stylus/typographic.styl'
typographic()


.sacramento	
	t-header()	
	font-family $palatino	
.mainHeading
	center()
	text-align center 
	& h1
		font-size rem(41.8868px)
		margin-top rem(9.88875px)
		margin-bottom rem(25.888px)
.representados-MainHeading
	text-align center
	margin-bottom 10px
.rule 
	rule(#DBDBDB)

.card
	line-height 1.55em